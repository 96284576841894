/* eslint-disable camelcase */

import React from 'react'
import { Button } from 'react-bootstrap'
import { useGetListingQuery } from '../../../../components/api'
import { SimpleTabs } from '../../../../components/Form'
import Layout from '../../../../components/Layout'
import ListingStatus from '../../../../components/ListingStatus'
import { vendorNavTo } from '../../../../components/navigation'
import VendorProductManifestTab from '../../../../components/viewListingTabs/vendorProductManifestTab'

const Page = (props) => {
  const { listingId } = props
  const listingQuery = useGetListingQuery({ listingId, isVendor: true }) // N.B. from this page, we are always the vendor!
  const { data: { global_listing_number = '' } = {} } = listingQuery

  // react-state
  const [page, setPage] = React.useState(0)

  return <Layout>

        <Layout.Title showBackButton>
            <div className="d-flex align-items-center">
              <h5>List Number: {global_listing_number}</h5>
              { listingQuery.isSuccess && <ListingStatus status={listingQuery.data.status} /> }
            </div>
            <Button variant="primary" type="submit" onClick={() => vendorNavTo.editListingPage(listingId)}>Edit Listing</Button>
        </Layout.Title>

        <Layout.CardGrey>

            <SimpleTabs elements={[
              { label: 'Product Manifest', onClick: () => setPage(0) }
              // { label: 'IMEI Upload', onClick: () => setPage(3) }
            ]} selectedIndex={page} size="md">

            </SimpleTabs>

            {/* Vendor */}
            { page === 0 && <VendorProductManifestTab {...props} />}
            {/* { page === 3 && <VendorImeiTab {...props} />} */}

        </Layout.CardGrey>

    </Layout>
}

export default Page
