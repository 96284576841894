/* eslint-disable camelcase */

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import { useGetListingQuery } from '../api'
import { useGetListingLotSkusQuery } from '../api/BuyerApi'
import MUIDataTable from '../MuiDatatableUtils'
import Spinner from '../spinner'

/*
 *
 * Page
 *
 */

const Page = (props) => {
  // props
  const { listingId } = props

  // state
  const [selectedLotId, setSelectedLotId] = React.useState('all')
  const [selectedLot, setSelectedLot] = React.useState({})

  // query
  const listingQuery = useGetListingQuery({ listingId, isVendor: true })
  const { isLoading = false, data: { listing_lots: listingLots = [] } = {} } =
    listingQuery
  const listingLotSkusQuery = useGetListingLotSkusQuery({
    listingId,
    listingLotId: selectedLotId,
    listingLots,
    isVendor: true
  })
  const { data: { listing_lot: listingLot = {} } = {} } = listingLotSkusQuery
  const { listing_lot_skus: listingLotSkus = [] } = listingLot

  const getSkuRow = (meta) => {
    return listingLotSkus[meta.currentTableData[meta.rowIndex].index]
  }

  return (
    <>
      <div className="card-body rounded-soft bg-white">
        {/* lot buttons */}
        <div className="pb-2">
          <Button
            size="sm"
            className={`m-1 ${selectedLotId === 'all' ? 'active' : ''}`}
            variant="outline-primary"
            onClick={() => setSelectedLotId('all')}
          >
            All
          </Button>
          {isLoading
            ? (
            <Spinner />
              )
            : (
                listingLots.map((lot) => (
              <Button
                key={lot.id}
                size="sm"
                className={`m-1 ${lot.id === selectedLotId ? 'active' : ''}`}
                variant="outline-primary"
                onClick={() => {
                  setSelectedLotId(lot.id)
                  setSelectedLot(lot)
                }}
              >
                Lot {lot.lot_number}
              </Button>
                ))
              )}
        </div>
        <hr
          style={{
            marginTop: '0px',
            marginLeft: '-20px',
            marginRight: '-20px'
          }}
        />

        {/* ANCHOR listingLotImeis */}
        {listingLotSkusQuery.isLoading && (
          <div className="d-flex justify-content-center p-5">
            <Spinner />
          </div>
        )}
        {listingLotSkusQuery.isError && (
          <Alert variant="danger" className="mb-0 mt-3 text-center">
            {'' + listingLotSkusQuery.error.message}
          </Alert>
        )}
        {listingLotSkusQuery.isSuccess && (
          <MUIDataTable
            columns={[
              {
                name: 'id',
                options: {
                  display: false
                }
              },
              { label: 'Lot', name: 'listing_lot_number' },
              { label: 'Make', name: 'make' },
              { label: 'Model', name: 'model' },
              { label: 'Memory', name: 'memory' },
              { label: 'Grade', name: 'grade' },
              { label: 'Device Type', name: 'device_type' },
              { label: 'Colour', name: 'colour' },
              { label: 'Kit', name: 'kit' },
              { label: 'Carrier', name: 'carrier' },
              {
                label: 'SIM Lock',
                name: 'sim_lock',
                options: { customBodyRender: (value) => value + '' }
              },
              {
                label: 'ID Lock',
                name: 'id_lock',
                options: { customBodyRender: (value) => value + '' }
              },
              {
                label: 'Region Lock',
                name: 'region_lock',
                options: { customBodyRender: (value) => value + '' }
              },
              { label: 'Quantity', name: 'qty' },
              {
                label: 'Unit Price',
                name: 'unit_price',
                options: {
                  customBodyRender: (value, tableMeta) =>
                    value
                      ? '$' + Math.round(value).toLocaleString()
                      : listingLot.selected_buyer_sku_bids
                        ? listingLot.selected_buyer_sku_bids[tableMeta.rowData[0]] !== undefined
                          ? '$' +
                          listingLot.selected_buyer_sku_bids[tableMeta.rowData[0]]
                          : ''
                        : ''
                }
              },
              { label: 'Participant', name: 'selected_buyer_name' }

            ]}
            data={listingLotSkus}
            options={{
              pagination: true,
              jumpToPage: false,
              print: false,
              search: true,
              download: true,
              sort: true,
              filter: true,
              viewColumns: true,
              selectableRows: 'none',
              elevation: 0,
              rowsPerPage: 100,
              setTableProps: () => ({ size: 'small' }),
              // exapandable rows...
              // responsive: 'standard',
              expandableRows: true,
              expandableRowsHeader: false,
              expandableRowsOnClick: true,
              renderExpandableRow: (rowData, rowMeta) => {
                // console.log('rowData', { rowData, rowMeta.dataIndex })
                const sku = listingLotSkus[rowMeta.dataIndex]
                const { imeis = [] } = sku
                const mappedImeis = imeis.map((imei, row) => ({
                  row: row + 1,
                  imei
                }))
                // const { listing_lot_sku_items: imeiList = [] } = expandedSku
                const colSpan = rowData.length + 1
                return (
                  <TableRow>
                    <TableCell colSpan={colSpan}>
                      <MUIDataTable
                        columns={[
                          { label: 'Row', name: 'row' },
                          { label: 'IMEI', name: 'imei' }
                          // {
                          //   label: ' ',
                          //   name: 'listing_lot_sku_item_id',
                          //   options: {
                          //     customBodyRender: (value) => <div className="d-flex justify-content-end">
                          //       <Button variant="outline-primary" size="sm" className="mr-2">Clear</Button>
                          //       <Button variant="primary" size="sm">Edit</Button>
                          //     </div>
                          //   }
                          // }
                        ]}
                        data={mappedImeis}
                        options={{
                          pagination: true,
                          jumpToPage: false,
                          print: false,
                          search: false,
                          download: false,
                          sort: false,
                          filter: false,
                          viewColumns: false,
                          selectableRows: 'none',
                          elevation: 0,
                          rowsPerPage: 100
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              }
            }}
          />
        )}

        {process.env.NODE_ENV !== 'production' && (
          <pre>selectedLot ={JSON.stringify(selectedLot, null, 2)}</pre>
        )}
        {process.env.NODE_ENV !== 'production' && (
          <pre>
            listingLotSkusQuery =
            {JSON.stringify(listingLotSkusQuery.data, null, 2)}
          </pre>
        )}
        {process.env.NODE_ENV !== 'production' && (
          <pre>listingQuery ={JSON.stringify(listingQuery.data, null, 2)}</pre>
        )}
      </div>
    </>
  )
}

export default Page
